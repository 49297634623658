var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "pb-2 flex items-center justify-between" },
      _vm._l(_vm.upperMenues, function (menu, index) {
        return _c(
          "button",
          {
            key: index,
            staticClass:
              "pt-3 font-semibold hover:text-black hover:cursor-pointer",
            class: menu.ids.every(function (val, i) {
              return val === _vm.value[i]
            })
              ? "text-black border-b-2 pb-0.5 border-black"
              : "text-brown-grey pb-1",
            on: {
              click: function ($event) {
                return _vm.changeActiveTab(menu.ids)
              },
            },
          },
          [
            "icon" in menu
              ? _c("img", {
                  staticClass: "w-5 h-5 mr-1 inline-block align-middle",
                  attrs: { src: menu.icon },
                })
              : _vm._e(),
            _c("span", { staticClass: "inline-block" }, [
              _vm._v(" " + _vm._s(menu.label.toUpperCase()) + " "),
            ]),
            menu.count
              ? _c(
                  "span",
                  {
                    staticClass:
                      "py-0.5 px-1 rounded-full text-xxs text-white bg-mango inline-block align-top",
                  },
                  [_vm._v(" " + _vm._s(menu.count) + " ")]
                )
              : _vm._e(),
          ]
        )
      }),
      0
    ),
    _vm.lowerMenues
      ? _c(
          "div",
          { staticClass: "pb-2 flex items-center justify-between" },
          _vm._l(_vm.lowerMenues, function (menu, index) {
            return _c(
              "button",
              {
                key: index,
                staticClass:
                  "pt-3 font-semibold hover:text-black hover:cursor-pointe",
                class: menu.ids.every(function (val, i) {
                  return val === _vm.value[i]
                })
                  ? "text-black border-b-2 pb-0.5 border-black"
                  : "text-brown-grey pb-1",
                on: {
                  click: function ($event) {
                    return _vm.changeActiveTab(menu.ids)
                  },
                },
              },
              [
                "icon" in menu
                  ? _c("img", {
                      staticClass: "w-5 h-5 mr-1 inline-block align-middle",
                      attrs: { src: menu.icon },
                    })
                  : _vm._e(),
                _c("span", { staticClass: "inline-block" }, [
                  _vm._v(" " + _vm._s(menu.label.toUpperCase()) + " "),
                ]),
                menu.count
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "py-0.5 px-1 rounded-full text-xxs text-white bg-mango inline-block align-top",
                      },
                      [_vm._v(" " + _vm._s(menu.count) + " ")]
                    )
                  : _vm._e(),
              ]
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }