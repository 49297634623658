var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-full" },
    [
      _vm.quotes.length > 0
        ? [
            _vm._l(_vm.quotes, function (quote, key) {
              return _c("QuoteListItem", {
                key: key,
                staticClass:
                  "border border-brown-grey grid grid-cols-12 gap-x-4 relative cursor-pointer mb-2 hover:bg-gray-200 rounded overflow-hidden font-normal",
                class: _vm.quotes.length === key + 1 ? "loadMore" : "",
                attrs: { value: quote },
                on: {
                  click: function ($event) {
                    return _vm.itemClicked(quote)
                  },
                },
              })
            }),
            _c("div", { staticClass: "flex justify-center mt-4" }, [
              _c(
                "button",
                {
                  staticClass:
                    "bg-brown-grey text-white font-bold rounded px-4 py-2 disabled:very-light-grey disabled:text-light-grey",
                  attrs: {
                    disabled: _vm.loading || _vm.finished,
                    id: "loadMoreButton",
                  },
                  on: { click: _vm.loadQuote },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.finished
                          ? _vm.$t("common.pagination.loaded_last")
                          : _vm.$t("common.pagination.load_more")
                      ) +
                      " "
                  ),
                ]
              ),
            ]),
          ]
        : _c("div", [
            _vm.loading
              ? _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("pages.consumers.movie_view.now_loading"))
                  ),
                ])
              : _c("p", [
                  _vm._v(_vm._s(_vm.$t("pages.commons.quotes.no_exist_quote"))),
                ]),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }