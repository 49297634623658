var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-screen" }, [
    _c(
      "div",
      { staticClass: "container mx-auto" },
      [
        _c("QuoteStatusTabs", {
          attrs: { upperMenuCount: 5 },
          model: {
            value: _vm.activeMenuIds,
            callback: function ($$v) {
              _vm.activeMenuIds = $$v
            },
            expression: "activeMenuIds",
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "border-b border-brown-grey" }),
    _c(
      "div",
      { staticClass: "container mx-auto my-5" },
      [
        _c(
          "KeepAlive",
          [
            _c("QuoteList", {
              key: _vm.activeMenuIds[0],
              tag: "component",
              attrs: { ids: _vm.activeMenuIds },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }