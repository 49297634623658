var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "border border-brown-grey grid grid-cols-12 gap-x-4 relative cursor-pointer mb-2 hover:bg-gray-200 rounded overflow-hidden font-normal",
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c("FlMovieImage", {
        staticClass: "col-span-2",
        attrs: { value: _vm.featuredImage },
      }),
      _c(
        "div",
        { staticClass: "col-span-10 grid grid-cols-2 items-center text-left" },
        [
          _c("p", { staticClass: "col-span-1" }, [
            _vm._v(
              _vm._s(_vm.$t("pages.commons.quotes.request_date")) +
                ": " +
                _vm._s(_vm._f("date")(_vm.quote.created_at))
            ),
          ]),
          _c("p", { staticClass: "col-span-1" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("pages.commons.quotes.current_state")) +
                ": " +
                _vm._s(
                  _vm.$t(
                    "pages.commons.quotes." +
                      _vm.quoteState(_vm.quote, _vm.loginUserRole)
                  )
                ) +
                " "
            ),
          ]),
          _c("p", { staticClass: "col-span-1" }, [
            _vm._v(
              _vm._s(_vm.$t("pages.commons.quotes.number_of_items")) +
                ": " +
                _vm._s(_vm.displayItemCount)
            ),
          ]),
          _c("p", { staticClass: "col-span-1" }, [
            _vm._v(
              _vm._s(_vm.$t("commons.fields.channels")) +
                ": " +
                _vm._s(_vm.channelNames)
            ),
          ]),
        ]
      ),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "absolute flex top-0 bottom-0 right-4 m-auto" },
      [
        _c("div", { staticClass: "flex items-center" }, [
          _c("img", {
            staticClass: "h-4",
            attrs: { src: require("@/assets/image/iconmonstr-arrow-25.svg") },
          }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }